<template>
    
    <b-card class="outer-card">
        <b-card class="inner-card">
            <div class="row" v-if="!mailSend">
                <div class="col-12 text-center" style="font-size: 1.5rem; font-weight: bold;">
                    POZABLJENO GESLO
                </div>
                <div class="col-12 text-center mt-2">
                    Spodaj vnesite svoj e-mail naslov in poslali vam bomo navodila za ponastavitev vašega gesla.
                </div>
                <div class="col-12 mt-2">
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-4 col-md-6 col-11">
                            <validation-observer ref="resetPasswordValidator">

                                <b-form-group label="Email">
                                    <validation-provider #default="{ errors }" name="e-naslov" rules="required|email">
                                        <b-form-input v-model="userEmail" name="login-email" placeholder="e-naslov" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </validation-observer>

                        </div>
                    </div>
                </div>
                <div class="col-12 mt-1">
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-4 col-md-6 col-11 d-flex justify-content-center">
                            <b-button @click="validationResetPassword()">
                                Ponastavi geslo
                                <b-spinner class="ml-1" small v-if="sendingMail"></b-spinner>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 text-center" style="font-size: 1.5rem; font-weight: bold;">
                    Navodila za ponastavitev gesla poslana!
                </div>
                <div class="col-12 text-center mt-2">
                    Na vaš naslov je bil uspešno poslan e-mail z navodili, kako pridobiti nove podatke za prijavo. <br>
                    Geslo je potrebno resetirati v 24h, saj po tem času link poteče.
                </div>
                <div class="col-12 mt-2">
                    <div class="row d-flex justify-content-center">
                        <div class="col-lg-4 col-md-6 col-11 d-flex justify-content-center">
                            <b-button @click="redirectToLogin()">
                                Nazaj na prijavo
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-card>
    </b-card>

</template>

<script>
    import { BCard, BFormGroup, BFormInput, BButton, BSpinner } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'

    export default {
        components:{
            BCard,
            BFormGroup,
            BFormInput,
            BButton,
            BSpinner,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                userEmail: null,
                sendingMail: null,
                mailSend: false,
                required,
                email
            }
        },
        methods: {
            async validationResetPassword() {
                const res = await this.$refs.resetPasswordValidator.validate()
                if (res) {
                    await this.resetPassowrd()
                }
            },
            async resetPassowrd() {
                try {
                    this.sendingMail = true
                    await this.$http.get(`/api/user/v1/reset_password?email=${ this.userEmail }`)
                    // this.$printSuccess('Na vaš email smo vam posredovali navodila za ponastavitev gesla!')  
                    this.sendingMail = false
                    this.mailSend = true
                } catch (err) {
                    if (err.response && err.response.data && err.response.data.message) {
                        this.$printError('Počakati morate 24h preden lahko zaprosite za ponovno ponastavitev gesla!')
                    } else {
                        this.$printError('Prišlo je do napake prosim poskusite ponovno!')
                    }
                    this.sendingMail = false
                }

            },
            redirectToLogin() {
                this.$router.push('/prijava')
            }
        }
    }
</script>


<style scoped>
    .outer-card{
        box-shadow: none !important;
        margin-left: -2000px !important;
        margin-right: -2000px !important;
        background: linear-gradient(180deg, rgba(234,243,249,1) 0%, rgba(234,243,249,1) 85%, rgba(255,255,255,1) 95%);
        min-height: 60vh !important;
    }

    .inner-card{
        box-shadow: none !important;
        margin-left: 2000px !important;
        margin-right: 2000px !important;
        border-radius: 10px !important;
    }

</style>